export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);
  // console.log(a);

  return a;
};

export const RequiredIs = {
  roles: [1],
  service_term: [1, 2, 3, 4],
  service_type: [1, 2, 3],
  product_type: [1, 2, 3, 4],
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export function timeShow(date) {

  if (date) {
    const timePart = date.split("T")[1]; // Splitting to get the time part
    const utcDateTime = new Date(date); // Creating a date object in UTC
    const istDateTime = new Date(utcDateTime.setMinutes(utcDateTime.getMinutes() + 330)); // Adding 5 hours 30 minutes for IST
    const istTime = istDateTime.toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata', hour12: false }); // Formatting IST time

    return istTime;
  }
}
